import React, { Fragment } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import cx from 'classnames'
import { useIntl } from 'intl'

import { constants } from 'helpers'

import { useProductCollaborationLabel } from 'modules/collaborations'
import type { Label as LabelType, RebrandLabel, TradingItemType } from 'typings/graphql'

import { Icon, Label, type BaseLabelProps } from 'components/dataDisplay'
import GraphicLabels from 'compositions/ProductGraphicLabels/ProductGraphicLabels'

import { useExcludeLabels } from './util'

import messages from './messages'


const PLUS_FREE_GIFT_EXCLUDED_TRADING_TEM_TYPES: TradingItemType[] = [ 'ALaCarte', 'PerfumeVial' ]

export type LabelsProps = {
  labels?: RebrandLabel
  excludeLabels?: LabelType[]
  upchargePrice?: number
  isEcommerce?: boolean
  isSmallCard?: boolean
  discount?: number
  tradingItemType?: TradingItemType
  withGenderLabels?: boolean
  removeZeroCents?: boolean
}

const Labels: React.FunctionComponent<LabelsProps> = (props) => {
  const {
    labels: rebrandLabels, upchargePrice, isEcommerce,
    isSmallCard, excludeLabels, discount, tradingItemType,
    withGenderLabels = false, removeZeroCents,
  } = props

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryCardsFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryCards)

  const isAiSummaryCardsEnabled = isAiSummaryEnabled && isAiSummaryCardsFeatureEnabled

  const intl = useIntl()

  const labels = useExcludeLabels(rebrandLabels, excludeLabels)
  let collaborationLabel = useProductCollaborationLabel({ labels: labels as ProductFragment.Base['rebrandLabels'] })

  const areExtraItemsExist = Boolean(labels?.extra?.length)
  const areMarketingItemsExist = Boolean(labels?.marketing?.length)
  const hasDiscount = typeof discount === 'number' && discount > 0
  const hasAiSummaryLabel = isAiSummaryCardsEnabled // TODO: Backend integration (AI reviews summary) - added on 2024-08-27 by girilloid

  if (!areExtraItemsExist && !areMarketingItemsExist && !collaborationLabel && !hasDiscount && !hasAiSummaryLabel) {
    return null
  }

  const mainLabels: {
    text?: string
    content?: React.ReactNode
    bgColor: BaseLabelProps['bgColor']
    textColor: BaseLabelProps['textColor']
    testId: string
  }[] = []

  if (hasDiscount) {
    mainLabels.push({
      text: intl.formatMessage(messages.discount, {
        discount,
      }),
      bgColor: 'brown-red',
      textColor: 'white',
      testId: 'discountLabel',
    })
  }

  if (collaborationLabel) {
    mainLabels.push({
      text: intl.formatMessage(collaborationLabel),
      bgColor: 'black',
      textColor: 'gold-30',
      testId: 'collaborationLabel',
    })
  }

  const items: { text: string, testId: string }[] = []

  if (upchargePrice && !isEcommerce) {
    items.push({
      text: `+${intl.formatPrice(upchargePrice, { removeZeroCents })}`,
      testId: 'upchargeLabel',
    })
  }

  if (labels?.extra?.includes('EXCLUSIVE')) {
    items.push({
      text: intl.formatMessage(messages.exclusive),
      testId: 'exclusiveLabel',
    })
  }

  if (withGenderLabels && labels?.extra?.includes('FOR_HER')) {
    items.push({
      text: intl.formatMessage(messages.forHer),
      testId: 'forHerLabel',
    })
  }

  if (withGenderLabels && labels?.extra?.includes('FOR_HIM')) {
    items.push({
      text: intl.formatMessage(messages.forHim),
      testId: 'forHimLabel',
    })
  }

  if (items.length) {
    mainLabels.push({
      content: items.map(({ text, testId }, index) => (
        <Fragment key={testId}>
          {index ? ' • ' : null}
          <span data-testid={testId}>{text}</span>
        </Fragment>
      )),
      bgColor: 'black',
      textColor: 'gold-30',
      testId: 'comboLabel',
    })
  }

  if (isEcommerce && labels?.extra?.includes('PLUS_FREE_GIFT') && !PLUS_FREE_GIFT_EXCLUDED_TRADING_TEM_TYPES.includes(tradingItemType)) {
    mainLabels.push({
      text: intl.formatMessage(messages.plusFreeGift),
      bgColor: 'gold-70',
      textColor: 'white',
      testId: 'freeGiftLabel',
    })
  }

  const hasMainLabels = Boolean(mainLabels.length)
  const graphicLabelsClassName = hasMainLabels ? 'pt-8' : 'pt-16'

  return (
    <>
      <div className="absolute left-0 top-0 z-10 flex flex-col items-start gap-10">
        {
          mainLabels.map(({ text, textColor, bgColor, content, testId }, index) => (
            <Label
              key={index}
              className="-ml-6 -mt-6"
              title={text}
              bgColor={bgColor}
              textColor={textColor}
              withTail
              data-testid={testId}
            >
              {content}
            </Label>
          ))
        }
        {
          areMarketingItemsExist && (
            <GraphicLabels
              className={cx('pl-8', isSmallCard && 'origin-top-left scale-75', graphicLabelsClassName)}
              items={labels.marketing}
              limit={isSmallCard ? 1 : 2}
            />
          )
        }
      </div>
      {
        hasAiSummaryLabel && (
          <Icon className="z-1 absolute right-10 top-10" name="24/ai-generated" aria-label="AI review summary" data-testid="aiSummaryLabel" />
        )
      }
    </>
  )
}


export default React.memo(Labels)
