import React from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import cx from 'classnames'

import { constants } from 'helpers'
import { getTradingItemDiscount } from 'helpers/getters'

import type { Label } from 'typings/graphql'

import { Text, Rating } from 'components/dataDisplay'
import { ChipSelect } from 'components/inputs'
import { Href } from 'components/navigation'

import ProductPrice from 'compositions/ProductPrice/ProductPrice'
import ProductImage from 'compositions/ProductImage/ProductImage'
import Labels from 'compositions/productCards/components/Labels/Labels'
import AddToButton, { type AddToButtonPropsWithoutProduct } from 'compositions/buttons/AddToButton/AddToButton'

import productCardMessages from '../messages'

import useProductCardDrift from './util/useProductCardDrift'

import messages from './messages'


export type ProductCardDriftProps = {
  className?: string
  bgColorClassName?: 'bg-light-beige' | 'bg-white'
  data: ProductFragment.Drift
  buttonProps: AddToButtonPropsWithoutProduct
  position?: number
  withoutLink?: boolean
  isStarterSet?: boolean
  withDescription?: boolean
  excludeLabels?: Label[]
  onProductClick?: (trackParams?: object) => void
  lazy?: boolean
}

const ProductCardDrift: React.FunctionComponent<ProductCardDriftProps> = (props) => {
  const {
    className,
    bgColorClassName = 'bg-light-beige',
    data,
    isStarterSet = false,
    buttonProps,
    position,
    withoutLink,
    withDescription = true,
    excludeLabels,
    onProductClick,
    lazy,
  } = props

  const { rating, upchargePrice, rebrandLabels, description: productDescription } = data

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryCardsFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryCards)

  const isAiSummaryCardsEnabled = isAiSummaryEnabled && isAiSummaryCardsFeatureEnabled

  const {
    name,
    image,
    price,
    description,
    varietyField,
    varietyItems,
    addToButtonProps,
    url,
    trackParams,
    cnstrcTrackingDataAttrs,
  } = useProductCardDrift({ data, position, isStarterSet })

  const isWhite = bgColorClassName === 'bg-white'
  const hasAiSummary = isAiSummaryCardsEnabled && true // TODO: Backend integration (AI reviews summary) - added on 2024-08-27 by girilloid

  const linkClickHandler = () => {
    if (typeof onProductClick === 'function') {
      onProductClick({
        action: 'link',
        ...trackParams,
      })
    }
  }

  const priceRow = (
    <ProductPrice
      className="mt-16 flex items-end justify-center"
      data={price}
    />
  )

  const topContent = (
    <>
      <ProductImage
        src={image}
        alt={description}
        bgColor={isWhite ? 'white' : 'light-beige'}
        lazy={lazy}
        remWidth={320}
      />
      <Text
        className="mt-8 line-clamp-2 max-h-[40rem]"
        message={name}
        style="h6"
        color="gold-50"
        data-testid="productName"
      />
      <Text className="mt-6 line-clamp-2 max-h-[40rem]" message={description} style="p3" />
      <Rating className="mx-auto mt-16" value={rating.avgRate} size={14} />
      {
        withDescription && priceRow
      }
    </>
  )

  const discount = price
    ? getTradingItemDiscount({ prices: price })
    : 0

  return (
    <div
      className={cx(className, 'relative flex flex-col justify-between px-24 pb-24 pt-16 text-center', bgColorClassName)}
      data-testid="driftProductCard"
      {...cnstrcTrackingDataAttrs}
    >
      <Labels
        labels={rebrandLabels}
        excludeLabels={excludeLabels}
        upchargePrice={upchargePrice}
        discount={discount}
        isEcommerce
      />
      {
        !withoutLink && url ? (
          <Href to={url} draggable={false} onClick={linkClickHandler}>
            {topContent}
          </Href>
        ) : (
          topContent
        )
      }
      <Text className="mt-16" message={messages.scentsTitle} style="p5" />
      <ChipSelect
        className="mt-12 justify-center"
        itemClassName="py-6 px-12"
        color="gold-50"
        labelStyle="p4"
        field={varietyField}
        options={varietyItems}
        data-testid="driftProductCardVarietyOptions"
      />
      {
        withDescription ? (
          <>
            <Text
              className="mt-auto line-clamp-3 max-h-[76rem] flex-auto pt-16"
              message={productDescription}
              messageTag="div"
              style="p3"
              html
            />
            {
              hasAiSummary && (
                <Text className="mt-8" message={productCardMessages.aiSummaryNote} align="center" color="gray-50" style="p6" />
              )
            }
          </>
        ) : priceRow
      }
      {
        Boolean(buttonProps) && (
          <AddToButton
            className="mt-24 w-full flex-none"
            size={48}
            {...addToButtonProps}
            {...buttonProps}
            onClick={() => {
              if (typeof onProductClick === 'function') {
                onProductClick({
                  action: 'Add to cart',
                  ...trackParams,
                })
              }

              if (typeof buttonProps.onClick === 'function') {
                buttonProps.onClick()
              }
            }}
          />
        )
      }
    </div>
  )
}


export default React.memo(ProductCardDrift)
